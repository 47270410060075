::-webkit-scrollbar {
  display: block;
}

iframe {
  height: 98vh;
}

body {
  overflow: hidden;
}
